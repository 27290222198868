/*Antd custom changes*/

html {
  --ant-primary-color: #D04898; /* Same of theme.palette.primary.main */
  --ant-primary-color-hover: #952c5a; /* Same of theme.palette.primary.dark */
  --ant-primary-color-active: #D04898; /* Same of theme.palette.primary.main */
  --ant-primary-5: #D04898; /* Same of theme.palette.primary.main */
  --ant-primary-color-outline: none;
}

/*Layout*/
.ant-layout {
  background: white;
}

/*Button*/
.ant-btn {
  line-height: 2;
  border-radius: 10px;
  font-size: medium;
  padding: 0 15px;
}

/*Form elements*/
.ant-form-item-has-error .ant-form-item-required {
  color: red;
}

.ant-form-item {
  margin-bottom: 10px;
}

.ant-form-item-label {
  label {
    width: 100%;
  }
}

.ant-input, .ant-picker {
  border-radius: 10px;
}

.ant-input-number {
  border-radius: 10px;
}

.ant-input-number-handler-wrap {
  border-radius: 10px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px;
}

.ant-col-sm-8 {
  max-width: fit-content;
}

/*Pagination Table*/
.ant-table-pagination.ant-pagination {
  /* margin: 20px 0; */
  padding-bottom: 20px;
}

.ant-table-wrapper {
  margin-top: 5px;
}

.ant-table-content {
  /*temp*/
  overflow: auto;
}
.ant-dropdown-menu-item-disabled{
  display: none;
}


/*Popover Confirmation*/
.ant-popover-message > .anticon {
  color: #D04898;
}
.ant-popover-buttons{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ant-popover-buttons > *{
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-popover-buttons .ant-btn-primary{
  background-color: #D04898;
  border-color: #D04898;
}
.ant-popover-buttons .ant-btn-primary:hover{
  background-color: #952c5a;
  border-color:  #952c5a;
}
.ant-popover-buttons .ant-btn-default:hover{
  background-color: transparent;
  border-color:  initial;
  color: initial;
}

/*Page Header*/
.ant-page-header-heading-left {
  display: flex;
  margin: 4px 0;
  overflow: hidden;
}

.ant-modal-body {
  overflow: auto;
}

.ant-modal-body .wizard-controls {
  padding: 5px 0;
}


/*Input Select*/
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f8377633;
}

/*Divider*/
.ant-divider-horizontal {
  margin: 10px 0;
}

/*Menu*/
.sidebar-rules .ant-menu-item-disabled {
  display: none;
}

.menu-ant {
  margin-left: 50px;
  display: flex;
  width: calc(100% - 160px);
  border: 0;
}

.largeModalButton{
  width: 100% !important;
}

.ant-table-row-level-1{
  background-color: rgb(237, 235, 235);
}